import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PageBannerImgBackground from "../components/PageBannerImgBackground/pageBannerImgBackground";

// import remark from 'remark'
// import remarkHTML from 'remark-html'

export const CareersTemplate = ({
  careersList,
  introSectionDescription,
  pageBannerTitle,
  pageBannerDescription,
  pageBannerImage,
}) => {
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [stateSelected, setStateSelected] = useState();
  const [stateList, setStateList] = useState(null);

  useEffect(() => {
    const stateListArray = [
      ...new Set(
        careersList.map((x) => x.state.toLowerCase().replace(/\s/g, ""))
      ),
    ];
    const initialCareers = careersList.filter(
      (item) => item.state.toLowerCase() === stateListArray[0]
    );
    setStateList(stateListArray);
    setSelectedSubMenu(initialCareers);
    setStateSelected(stateListArray[0]);

    const script = document.createElement("script");
    script.src = "https://www.workable.com/assets/embed.js";
    script.async = true;
    document.body.appendChild(script);
    // whr_embed(596876, {detail: 'titles', base: 'jobs', zoom: 'country', grouping: 'none'})
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const fireSelectedSubMenu = (usState) => {
    const selectedList = careersList.filter(
      (item) => item.state.toLowerCase() === usState.toLowerCase()
    );
    setSelectedSubMenu(selectedList);
    setStateSelected(usState);
  };

  const getImagePath = (image) => {
    if (image?.publicURL) return image.publicURL;
    else return image;
  };

  return (
    <>
      <div className="careers-wrapper">
        <PageBannerImgBackground
          imgPath={getImagePath(pageBannerImage)}
          title={pageBannerTitle}
          description={pageBannerDescription}
        />

        <div className="careers-content-wrapper">
          <div className="intro-statement-block">
            <h2 className="trademarked">The Mission Starts Here</h2>
            <div
              dangerouslySetInnerHTML={{ __html: introSectionDescription }}
            />
          </div>

          <div id="whr_embed_hook"></div>

          {/* <div className='columns-wrapper'>
            <div className='left-column'>
                {stateList && stateList.map(item => <div className='list-item' style={{background: item.toLowerCase() === stateSelected ? 'rgb(219, 218, 218)' : ''}}>
                      <div onClick={() => fireSelectedSubMenu(item)}>{item}</div>
                    
                </div>)}
            </div>

              <div className='right-column'> 
                    <h3> Open Positions In {stateSelected}</h3>
                    { selectedSubMenu && selectedSubMenu.map(item => <div className='careers-list-item' key={item.title} href={item.link}>
                      <div className='title'> 
                       {item.title} | <span> {item.location} </span>
                      </div>

                      <div className='btn-black'>
                      <a href={item.link}> <button>VIEW POSITION</button> </a>
                      </div>
              </div>)}
              </div>
        </div>    */}
        </div>
        <iframe
            className="careers-workable"
            src={`https://apply.workable.com/theinclab/`}
          />
      </div>
    </>
  );
};

CareersTemplate.propTypes = {
  careersList: PropTypes.array,
  introSectionDescription: PropTypes.string,
  pageBannerTitle: PropTypes.string,
  pageBannerDescription: PropTypes.string,
  pageBannerImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const CareersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      title={frontmatter.pageBannerTitle}
      description="TheIncLab is hiring passionate and innovative talented individuals. Learn more here about our career opportunities and posted jobs. "
    >
      <CareersTemplate
        careersList={frontmatter.careersList}
        pageBannerTitle={frontmatter.pageBannerTitle}
        pageBannerDescription={frontmatter.pageBannerDescription}
        pageBannerImage={frontmatter.pageBannerImage}
        introSectionDescription={frontmatter.introSectionDescription}
      />
    </Layout>
  );
};

CareersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CareersPage;

export const pageQuery = graphql`
  query CareersPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "careers-hub" } }) {
      frontmatter {
        pageBannerTitle
        pageBannerDescription
        pageBannerImage {
          extension
          publicURL
        }
        careersList {
          title
          link
          state
          location
        }
        introSectionDescription
      }
    }
  }
`;
